import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { PropsWithClassName } from '@/shared/types/util';

import { TypographyPublicProps } from '../Typography';

import style from './TypographyList.module.css';

type TypographyListProps = Partial<TypographyPublicProps> & PropsWithClassName & PropsWithChildren;

const TypographyList = ({ className, children }: TypographyListProps) => {
  if (!children) return null;

  return <ul className={classNames(style.root, className)}>{children}</ul>;
};

export default TypographyList;
