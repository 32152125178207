import React from 'react';

import Carousel from '/components/Carousel';
import CollectionItem from '/components/Main/components/CollectionItem';

import data from './data';

const CollectionList = () => (
  <Carousel slideWidth={320} isArrowButtonShadowEnabled>
    {data.map((item, index) => (
      <CollectionItem
        key={index}
        name={item.name}
        url={item.url}
        imgSrc={item.imgSrc}
        count={item.count}
      />
    ))}
  </Carousel>
);

export default CollectionList;
