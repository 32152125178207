import debug from 'debug';

import Main from '../components/Main';
import getBaseInitialProps from '../helpers/getBaseInitialProps';
import MainFiltersApi from '../src/core/api/main-filters';
import { processingApiErrors } from '../types/errors';

const log = debug('pages:index');

const mainFilterApi = new MainFiltersApi();

export const getServerSideProps = async (ctx) => {
  const { query } = ctx;
  const { debug: isDebug } = query;

  if (isDebug) {
    debug.enable('*');
    log('Start getServerSideProps');
  }

  try {
    const props = await getBaseInitialProps({ ctx: { ...ctx }, isMainPageLoad: true });

    {
      const filterList = await mainFilterApi.getFilterList(props.regionId);

      props.filters = filterList?.filters ?? [];
    }

    const pageData = { props };

    if (isDebug) {
      log('Finish getServerSideProps');
    }

    return pageData;
  } catch (e) {
    return processingApiErrors(e);
  }
};

export default Main;
