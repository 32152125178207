// import { ReactComponent as ArrowMiniDown } from './arrowMiniDown.svg';
import React from 'react';

const ArrowDown = (
  <svg width='16' height='9' viewBox='0 0 16 9' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.5 2.15246L8 8.5L15.5 2.15246L13.5165 0.5L8 5.16885L2.48347 0.499999L0.5 2.15246Z' />
  </svg>
);

export default ArrowDown;
