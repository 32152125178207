const img1 = '/static/imgs/collectionList/kovry-s-geometricheskim-risunkom.jpg';
const img2 = '/static/imgs/collectionList/kovry-sheggi.jpg';
const img3 = '/static/imgs/collectionList/shelkovye-kovry-iz-indii.jpg';
const img4 = '/static/imgs/collectionList/kitajskie-shelkovye-kovry.jpg';
const img5 = '/static/imgs/collectionList/kovry-v-spalnyu.jpg';
const img6 = '/static/imgs/collectionList/kovry-v-gostinuyu.jpg';
const img7 = '/static/imgs/collectionList/odnotonnye-kovry.jpg';
const img8 = '/static/imgs/collectionList/stilnye-kovry.jpg';
const img9 = '/static/imgs/collectionList/kovry-loft.jpg';
const img10 = '/static/imgs/collectionList/kovry-iz-iskusstvennogo-shelka.jpg';
const img11 = '/static/imgs/collectionList/iskusstvennye-kovry.jpg';
const img12 = '/static/imgs/collectionList/iranskie-shelkovye-kovry.jpg';
const img13 = '/static/imgs/collectionList/naturalnye-kovry.jpg';
const img14 = '/static/imgs/collectionList/kovrovye-dorozhki.jpg';

export default [
  {
    name: ' Ковры с геометрическим рисунком',
    url: '/catalog/kovry-s-geometricheskim-risunkom/',
    imgSrc: img1,
    count: 178,
  },
  {
    name: 'Ковры с длинным ворсом - мягкие и нежные',
    url: '/catalog/kovry-sheggi/',
    imgSrc: img2,
    count: 121,
  },
  {
    name: 'Шелковые ковры из Индии - ручная работа, различные стили',
    url: '/catalog/shelkovye-kovry-iz-indii/',
    imgSrc: img3,
    count: 152,
  },
  {
    name: 'Традиционные китайские шелковые ковры',
    url: '/catalog/kitajskie-shelkovye-kovry/',
    imgSrc: img4,
    count: 190,
  },
  {
    name: 'Ковры в спальню - нежность и уют в вашем доме',
    url: '/catalog/kovry-v-spalnyu/',
    imgSrc: img5,
    count: 173,
  },
  {
    name: 'Ковры в гостиную - найдем решение для любого интерьера',
    url: '/catalog/kovry-v-gostinuyu/',
    imgSrc: img6,
    count: 110,
  },
  {
    name: 'Однотонные ковры - подчеркните свой интерьер',
    url: '/catalog/odnotonnye-kovry/',
    imgSrc: img7,
    count: 118,
  },
  {
    name: 'Стильные ковры - добавьте жизнь в интерьер',
    url: '/catalog/stilnye-kovry/',
    imgSrc: img8,
    count: 109,
  },
  {
    name: 'Ковры лофт - от спокойных до экспрессивных',
    url: '/catalog/kovry-loft/',
    imgSrc: img9,
    count: 149,
  },
  {
    name: 'Ковры из искусственного шелка - качество по доступной цене',
    url: '/catalog/kovry-iz-iskusstvennogo-shelka/',
    imgSrc: img10,
    count: 131,
  },
  {
    name: 'Ковры из искусственных материалов - практично и стильно',
    url: '/catalog/iskusstvennye-kovry/',
    imgSrc: img11,
    count: 191,
  },
  {
    name: 'Иранские ковры - шедевры мирового ковроткачества!',
    url: '/catalog/iranskie-shelkovye-kovry/',
    imgSrc: img12,
    count: 147,
  },
  {
    name: 'Ковры из натуральных материалов',
    url: '/catalog/naturalnye-kovry/',
    imgSrc: img13,
    count: 130,
  },
  {
    name: 'Ковровые дорожки - любые размеры, цвета и узоры',
    url: '/catalog/f/shape-8/',
    imgSrc: img14,
    count: 166,
  },
];
