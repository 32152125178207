import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './MiniReview.module.css';

import Typography from '../Typography';
import Markdown from '../../controls/Markdown';


const MiniReview = memo(({ review }) => {
  const reviewText = review?.text;
  const reviewAuthor = review?.author;
  const reviewDate = review?.date;
  const reviewManager = review?.manager;
  const reviewShop = review?.shop;
  const reviewCity = review?.city || review?.town;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isShowExpandButton, setIsShowExpandButton] = useState(true);

  const textRef = useRef();

  useEffect(() => {
    if (textRef?.current) {
      const textRect = textRef?.current?.getBoundingClientRect();

      if (textRect) {
        setIsShowExpandButton(textRect.height && textRect.height > 64);
      }
    }
  });

  return (
    <div className={classnames(styles.review, { [styles.expanded]: isExpanded, [styles.collapsable]: !!isShowExpandButton && !isExpanded })}>
      <Typography variant='body3' component='div' className={styles.reviewDate}>{reviewDate}</Typography>
      <div className={styles.textBlock}>
        <div className={classnames(styles.reviewText)}>
          <Markdown ref={textRef} source={reviewText} />
        </div>
      </div>
      {!!reviewAuthor && <Typography variant='body1' component='div' bold className={styles.reviewAuthor}>{reviewAuthor}</Typography>}
      {!!reviewManager && (
      <Typography variant='body3' component='div' className={styles.reviewManager}>
        О консультанте
        &nbsp;
        {reviewManager}
      </Typography>
      )}
      {!!reviewShop && <Typography variant='body3' component='a' color='link' className={styles.reviewShop} href={reviewShop.url}>{reviewShop.text}</Typography>}
      {!!reviewCity && <Typography variant='body3' component='div' className={styles.reviewCity}>{reviewCity}</Typography>}
      {isShowExpandButton && (
        <button className={styles.reviewButton} tabIndex='0' type='button' onClick={() => { setIsExpanded(!isExpanded); }}>
          { !isExpanded ? 'Читать далее' : 'Свернуть'}
        </button>
      )}
    </div>
  );
});


MiniReview.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'body1', 'body2', 'body3', 'button', null]),
  bold: PropTypes.bool,
  semibold: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'accent', null]),
  component: PropTypes.string,
  className: PropTypes.string,
};

MiniReview.defaultProps = {
  variant: null,
  bold: false,
  color: null,
  component: null,
  className: null,
};


export default MiniReview;
