/* eslint-disable security/detect-object-injection */
import { DataHTMLAttributes, forwardRef, HTMLAttributes, memo } from 'react';
import classNames from 'classnames';

import { TYPOGRAPHY_VARIANT } from './constants';

import style from './Typography.module.css';

export const HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export type TypographyVariant = (typeof TYPOGRAPHY_VARIANT)[keyof typeof TYPOGRAPHY_VARIANT];

export type TypographyElement =
  | 'cite'
  | 'dt'
  | 'dd'
  | 'p'
  | 'span'
  | 'div'
  | 'label'
  | 'strong'
  | 'em'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'blockquote'
  | 'time'
  | 'li'
  | 'div';

export interface TypographyPublicProps {
  isBreakpointsUpdate: boolean;
  custom: boolean;
  customSmall: boolean;
  element: TypographyElement;
  inherited: boolean;
  id: string;
  strike: boolean;
  secondary: boolean;
  strong: boolean;
  variant: TypographyVariant;
  underlined: boolean;
  uppercase: boolean;
}

export interface TypographyProps
  extends Partial<TypographyPublicProps>,
    DataHTMLAttributes<unknown>,
    HTMLAttributes<unknown> {
  component?: React.FC | React.NamedExoticComponent;
  htmlFor?: string;
  innerHtml?: boolean;
}

const Typography = forwardRef<any, TypographyProps>((props, ref) => {
  const {
    isBreakpointsUpdate,
    component,
    id,
    inherited,
    children,
    element = 'span',
    variant = 'text100',
    className,
    underlined,
    innerHtml = false,
    strike,
    secondary,
    strong,
    uppercase,
    ...rest
  } = props;

  const Component = component || element;
  const isHeading = HEADINGS.includes(variant);

  const classes = classNames(
    style.root,
    style[variant],
    {
      [style.isBreakpointsUpdate]: !!isBreakpointsUpdate,
      [style.underline]: underlined,
      [style.strike]: strike,
      [style.uppercase]: uppercase,
      [style.secondary]: secondary,
      [style.strong]: strong,
      [style.inherited]: inherited,
      [style.heading]: isHeading,
    },
    className
  );

  const innerHtmlProps = innerHtml && children ? { dangerouslySetInnerHTML: { __html: children } } : { children };

  return <Component id={id} ref={ref} {...innerHtmlProps} {...rest} className={classes} />;
});

Typography.displayName = 'Typography';

export default memo(Typography);
