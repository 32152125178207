import React from 'react';

import { declOfNum } from '/helpers/tools';

import styles from './CollectionItem.module.css';

const CollectionItem = ({
  name, url, imgSrc, count,
}) => (
  <a
    href={url}
    className={styles.collectionItem}
    onClick={() => {
      window.dataLayer?.push({
        event: 'promoClick',
        eventCategory: 'Главная',
        eventAction: 'Дизайнерские подборки',
        eventLabel: name, // текст баннера
        ecommerce: {
          promoClick: {
            promotions: [{
              name, // н-р Ковры в загородный дом
              position: 'Главная. Дизайнерские подборки',
            }],
          },
        },
      });
    }}
  >
    <div className={styles.collectionItemImg}>
      <img src={imgSrc} alt='-' />
    </div>
    <div className={styles.collectionItemTitle}>{name}</div>
    <div className={styles.collectionItemLink}>
      {count}
      {declOfNum(count, ['ковер', 'ковра', 'ковров'])}
&nbsp;›
    </div>
  </a>
);

export default CollectionItem;
