import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Link from 'next/link';

import styles from './MobileFeaturesBanner.module.css';

const MobileFeaturesBanner = ({ className, info, isAmp }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(c => (c + 1) % 3);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={classnames(styles.mainMobileFeaturesContent, className, { 'mobile-features-content': isAmp })}>
      <div className={classnames(styles.mainMobileFeaturesItems, { 'mobile-features-content__items': isAmp })}>
        <Link
          href='/dostavka-i-oplata/#shipping'
          className={classnames(styles.mainMobileFeaturesItem, { 'mobile-features-content__item': isAmp, [styles.active]: currentSlide === 0 })}>

          <img src='/static/imgs/main/mobile-delivery-icon.svg' width='20' height='17' alt='Бесплатная доставка' loading='lazy' />
          {!info?.isFreeShippingDisabled ? 'Бесплатная доставка' : 'Доставка'}

        </Link>
        <Link
          href='/demonstration/'
          className={classnames(styles.mainMobileFeaturesItem, { 'mobile-features-content__item': isAmp, [styles.active]: currentSlide === 1 })}>

          <img src='/static/imgs/main/mobile-fit-icon.svg' width='23' height='17' alt='Бесплатная демонстрация ковров у вас дома' loading='lazy' />
          {!info?.isFreeShippingDisabled ? 'Бесплатная демонстрация' : 'Демонстрация'}
          {' '}ковров у вас дома
        </Link>
        <Link
          href='/vozvrat/'
          className={classnames(styles.mainMobileFeaturesItem, { 'mobile-features-content__item': isAmp, [styles.active]: currentSlide === 2 })}>

          <img src='/static/imgs/main/mobile-return-icon.svg' width='27' height='17' alt='Бесплатный возврат в течение 100 дней' loading='lazy' />
          {!info?.isFreeShippingDisabled ? 'Бесплатный возврат' : 'Возврат'}
          {' '}в течение 100 дней
        </Link>
      </div>
    </div>
  );
};

export default MobileFeaturesBanner;
